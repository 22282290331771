<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3 align-self-center">
        <h1 class="mb-3" style="margin-top: 30px;">Experimente</h1>
      </div>
      <div class="col-md-8 my-3">
        <p>
          <!-- <br/>
          <br/>
          <br/>
          <br/>
          <br/> -->
          Colocamos em prática a nossa autêntica vocação omnichannel, após meses em estudo de possibilidades e aprimoramento de processos internos. A consequência disso está aqui em diferentes dinâmicas de ramificações para acessos e consultas. Tudo para assegurar a máxima performance na jornada do consumidor. Experimente! Estamos aqui para melhor atender sempre!!!
          <!-- <br/>
          <br/>
          <br/>
          <br/>
          <br/> -->
        </p>
      </div>
    </div>
  </div>
  
</template>

<script>

export default {
    name: "Termos",
     components: {
    },
    data: () => ({
        links: [
        ]
    }),
    methods: {
    },
    async created() {
        
    },
    computed: {
    }
};
</script>
